
//$title-font: 'Helvetica Now', 'Helvetica Neue', Helvetica, Univers, Arial, sans-serif;
//$styled-font:'Helvetica Now', 'Helvetica Neue', Helvetica, Univers, Arial, sans-serif;
//$base-font:  'Helvetica Now', 'Helvetica Neue', Helvetica, Univers, Arial, sans-serif;

$base-font:  'Open Sans', sans-serif;
$title-font: 'Open Sans Condensed', sans-serif;
$styled-font:'Open Sans', sans-serif;
/*
$title-font:'Bai Jamjuree', sans-serif;
$styled-font:'Bai Jamjuree', serif;
$base-font:'Bai Jamjuree', sans-serif;
*/
/*
$title-font:'Public Sans', sans-serif;
$styled-font:'Public Sans', serif;
$base-font:'Public Sans', sans-serif;
*/

//$primary-color:#ef7301;
$primary-color:#27598C;
$secondary-color: #EF7D00;
$white:#ffffff;
$danger-color:#c9302c;
$success-color:#5cb85c;
$warning-color:#f0ad4e;
$default-color:#676c74;
$dark-color:#141414;
//$info-color:#5bc0de;
$info-color:#71B7FF;
$gray-light:#e3e3e4;
$black:#000000;
$dark-gray:#676c74;

$color-it: #EF9615;
$color-science: #37BC9B;
$color-technics:#3bafda;
$color-yellow: yellow;
$color-red: red;

$fiw-darker-gray:#4C5055;
$fiw-dark-gray:#676c74;
$fiw-medium-gray1:#757A82;
$fiw-medium-gray2:#878B92;
$fiw-medium-gray3:#ACB0B5;


