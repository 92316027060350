/*
Pagination
*/
.page-link{
    color: $primary-color;
}
.page-item.active .page-link {
    z-index: 2;
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
}

