/*
Countdown
*/
.count-down{
    text-align:center;
    color:$secondary-color;
    .cd-row{
        display: inline-block;
        background-color: $primary-color;
        border-radius: 4px;
        margin: 0 2px 0 2px;
        >div{
            display: inline-block;
            width:80px;
            text-transform: uppercase;
        }
    }
    h1{
       font-size: 2.9rem;
       color:white;
    }
}

@media (max-width: 360px) {
    .count-down .cd-row div {
        width:60px;
    }
}


.bg-parallax{
    .count-down{
        h1{
            color: #fff;
        }
    }
}
