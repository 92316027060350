/*
Clients style
*/
.clients-grid{
    list-style: none;
    overflow: hidden;
    padding:0;
    li {
        img{
            display: block;
            margin: 0 auto;
        }
        width: 25%;
        float: left;
        position: relative;
        width: 20%;
        padding: 20px 0;
        -webkit-backface-visibility: hidden;
        &:before{
            content: '';
            position: absolute;
            height: 100%;
            top: 0;
            left: -1px;
            border-left: 1px solid #eee;
        }
        &:after{
            content: '';
             position: absolute;
            width: 100%;
            height: 0;
            top: auto;
            left: 0;
            bottom: -1px;
            border-bottom: 1px solid #eee;
        }
    }
    &.column4{
        li{
            width: 25%;
        }
    }
}
.carousel-client{
    .item{
        img{
            max-width:100%;
        }
    }
}

