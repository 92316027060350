@mixin gradient1($from, $to) {
    // https://gist.github.com/thbar/1319313


    /* fallback/image non-cover color */
    background-color: $from;

    /* Firefox 3.6+ */
    background-image: -moz-linear-gradient($from, $to);

    /* Safari 4+, Chrome 1+ */
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));

    /* Safari 5.1+, Chrome 10+ */
    background-image: -webkit-linear-gradient($from, $to);

    /* Opera 11.10+ */
    background-image: -o-linear-gradient($from, $to);
}

@mixin gradient-vertical($startColor: #555, $endColor: #333) {
    background-color: mix($startColor, $endColor, 60%);
    background-image: -moz-linear-gradient(top, $startColor, $endColor); // FF 3.6+
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(top, $startColor, $endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(top, $startColor, $endColor); // Opera 11.10
    background-image: linear-gradient(to bottom, $startColor, $endColor); // Standard, IE10
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($startColor)}', endColorstr='#{ie-hex-str($endColor)}', GradientType=0); // IE9 and down
}



/* COLOR VARIABLES
============================================= */
$orange: #ffa600;
$blue: #82d2e5;
$green:#c1d72e;
/* GRADIENT LISTS
============================================= */
// https://codepen.io/cathydutton/pen/dPPpWj

$list1: $blue,
$green,
$orange;

$list2: $blue 0%,
$blue 33%,
$green 33%,
$green 66%,
$orange 66%,
$orange 100%;

$list3: $blue 0%,
$blue 10%,
$green 25%,
$green 39%,
$orange 79%,
$orange 100%;

$list4: $blue,
$green,
$orange,
$blue,
$green,
$orange,
$blue,
$green,
$orange;

@mixin gradient($direction, $list) {
    background: -webkit-linear-gradient($direction, $list);
    background: -moz-linear-gradient($direction, $list);
    background: -o-linear-gradient($direction, $list);
    background: linear-gradient($direction, $list);
}