
@font-face {
    font-family: 'FontAwesome';
    src: url("../fonts/font-awesome/fontawesome-webfont.eot?v=4.7.0");
    src: url("../fonts/font-awesome/fontawesome-webfont.eot?#iefix&v=4.7.0") format('embedded-opentype'), 
    url("../fonts/font-awesome/fontawesome-webfont.woff2?v=4.7.0") format('woff2'), 
    url("../fonts/font-awesome/fontawesome-webfont.woff?v=4.7.0") format('woff'), 
    url("../fonts/font-awesome/fontawesome-webfont.ttf?v=4.7.0") format('truetype'), 
    url("../fonts/font-awesome/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format('svg');
    font-weight: normal;
    font-style: normal;
}

/*themify icons*/

@font-face {
    font-family: 'themify';
    src: url("../fonts/themify-icons/themify.eot");
    src: url("../fonts/themify-icons/themify.eot?#iefix") format('embedded-opentype'),
         url("../fonts/themify-icons/themify.woff") format('woff'),
         url("../fonts/themify-icons/themify.ttf") format('truetype'),
         url("../fonts/themify-icons/themify.svg") format('svg');
    font-weight: normal;
    font-style: normal;
}

/**et line icons**/


@font-face {
    font-family: 'et-line';
    src: url('../fonts/et-line-font/et-line.eot');
    src: url('../fonts/et-line-font/et-line.eot?#iefix') format('embedded-opentype'),
         url('../fonts/et-line-font/et-line.woff') format('woff'),
         url('../fonts/et-line-font/et-line.ttf') format('truetype'),
         url('../fonts/et-line-font/et-line.svg#et-line') format('svg');
    font-weight: normal;
    font-style: normal;
}

//@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800|Open+Sans:300,300i,400,400i,600,700,800');
//@import url('https://fonts.googleapis.com/css?family=Droid+Serif:400,400i');
//@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@200;300;400;500;600;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800&display=swap');


/* open-sans-condensed-300 - latin */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-300.eot');
    /* IE9 Compat Modes */
    src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'),
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-300.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-300.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-300.svg#OpenSansCondensed') format('svg');
    /* Legacy iOS */
}

/* open-sans-condensed-300italic - latin */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-300italic.eot');
    /* IE9 Compat Modes */
    src: local('Open Sans Condensed Light Italic'), local('OpenSansCondensed-LightItalic'),
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-300italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-300italic.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-300italic.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-300italic.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-300italic.svg#OpenSansCondensed') format('svg');
    /* Legacy iOS */
}

/* open-sans-condensed-700 - latin */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Open Sans Condensed Bold'), local('OpenSansCondensed-Bold'),
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-700.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/open-sans-condensed-v14-latin/open-sans-condensed-v14-latin-700.svg#OpenSansCondensed') format('svg');
    /* Legacy iOS */
}

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-v17-latin/open-sans-v17-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/open-sans-v17-latin/open-sans-v17-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v17-latin/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/open-sans-v17-latin/open-sans-v17-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans-v17-latin/open-sans-v17-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/open-sans-v17-latin/open-sans-v17-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v17-latin/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/open-sans-v17-latin/open-sans-v17-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/open-sans-v17-latin/open-sans-v17-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/open-sans-v17-latin/open-sans-v17-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-800italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v17-latin/open-sans-v17-latin-800italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
