/*
As CLassic Template
Version- 3.8
*/
@import"fonts.scss";

@import"scss-classic-main/_variables.scss";

@import"mixins.scss";

@import"scss-classic-main/_base.scss";
@import"scss-classic-main/_preloader.scss";
@import"scss-classic-main/_buttons.scss";
@import"scss-classic-main/_header.scss";
@import"scss-classic-main/_dropdown.scss";
@import"scss-classic-main/_alerts-notifications.scss";
@import"scss-classic-main/_accordions.scss";
@import"scss-classic-main/_accounts.scss";
@import"scss-classic-main/_blockquotes.scss";
//@import"scss-classic-main/_breadcrumb.scss";
@import"scss-classic-main/_blog.scss";
@import"scss-classic-main/_carousel.scss";
@import"scss-classic-main/_clients.scss";
@import"scss-classic-main/_counter-countdown.scss";
@import"scss-classic-main/_hero-templates.scss";
@import"scss-classic-main/_icon-boxes.scss";
@import"scss-classic-main/_img-boxes.scss";
@import"scss-classic-main/_modals.scss";
@import"scss-classic-main/_page-titles.scss";
@import"scss-classic-main/_paginations.scss";
//@import"scss-classic-main/_portfolio.scss";
//@import"scss-classic-main/_pricing-boxes.scss";
@import"scss-classic-main/_progress-bar.scss";
@import"scss-classic-main/_promo-boxes.scss";
@import"scss-classic-main/_revolution-slider.scss";
@import"scss-classic-main/_sidebar.scss";
@import"scss-classic-main/_tabs.scss";
@import"scss-classic-main/_team.scss";
@import"scss-classic-main/_testimonials.scss";
@import"scss-classic-main/_title-headings.scss";
@import"scss-classic-main/_cards.scss";
@import"scss-classic-main/_top-bars.scss";
@import"scss-classic-main/_career.scss";
@import"scss-classic-main/_timeline.scss";
//@import"scss-classic-main/_smart-forms.scss";
@import"scss-classic-main/_typed.js.scss";
@import"scss-classic-main/_image-text-card.scss";
@import"scss-classic-main/_footer.scss";
@import"scss-classic-main/_responsive-utilities.scss";
/**intro css (you can remove it)**/
@import"scss-classic-main/_intro-assan.scss";
@import"scss-classic-main/_responsive.scss";

/*
@import"scss-p/_p-header.scss";
@import"scss-p/_p-sections.scss";
@import"scss-p/_p-positionierung.scss";
@import"scss-p/_p-zertifizierung.scss";
@import"scss-p/_p-cta.scss";
@import"scss-p/_p-veranstaltung.scss";
@import"scss-p/_p-mainpromo.scss";
@import"scss-p/_p-counter.scss";
@import"scss-p/_p-custom.scss";
@import"scss-p/_p-news.scss";
@import"scss-p/_p-forschungsprojekte.scss";
@import"scss-p/_p-footer.scss";
*/

div.bg-primary {
    h1, h2, h3, h4, h5, h6, p, ul, li {
        color:white;
    }
}

table tr td {
    vertical-align: top;
}

.navbar.bg-dark {
    background-color: $primary-color !important;
}

.orange {
    color: $primary-color;
}

.pushy {
    background: $primary-color;
    overflow: auto;
}
.pushy-submenu button {
    color: white;
    font-weight: bold;
}
.pushy-submenu button:hover {
    background: darken($primary-color,20%);
}

.text-secondary {
    color: $secondary-color;
}


.bg-it {
    background-color: $color-it!important;
}

.bg-science {
    background-color: $color-science !important;
}

.bg-technics {
    background-color: $color-technics !important;
}

.badge-primary-light {
    background-color: lighten($primary-color, 40%) !important;
    color: white;
}

.primary-color-faded {
    color: lighten($primary-color, 40%);
}

div.exhibitor-item {
    //border-bottom: solid 1px #ddd;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
    color: black;
    a:hover {
        color: $secondary-color;
    }
    .btn {
        border-radius: 2px;
        font-weight: 700;
        font-size: .9rem;
        padding: 14px 30px;
    }
}
div.exhibitor-card .border-top {
    border-top: solid 1px #ddd;
}
div.exhibitor-card .border-bottom {
    border-bottom: solid 1px #ddd;
}
div.favindicator,
span.favindicator {
    text-align: center;
    font-size: 24px;
    color: #EF9615;
    display: none;
}

.bg-faded2 {
    background-color: rgba($primary-color, .15);
}

.btn.btn-slim {
    border-radius: 2px;
    cursor: pointer;
    font-weight: 700 !important;
    font-size: 14px !important;
    padding: 5px 5px 5px 5px !important;
    text-transform: none;
    vertical-align: middle;
    letter-spacing: 0em;
}

.btn.btn-yellow {
    background-color: $color-yellow;
    color: $primary-color;
}
.btn.btn-yellow:hover {
    background-color: darken($color-yellow,3%);
    color: $primary-color;
}

.btn.btn-red{
    background-color: $color-red;
    color: white;
}

.btn.btn-red:hover {
    background-color: darken($color-red, 3%);
    color: white;
}






.tb-accordion{
    line-height: 1.65714286em;
}
.tb-accordion .card .card-header a .rotate-icon {
    transition: all .6s;
    font-size: 16px;
    color: $primary-color;
    font-weight: bold;
    margin-top: 3px;
}
.tb-accordion .card .card-header a .text-plus.rotate-icon {
    font-size: 18px;
    font-family: $base-font;
    //font-family: "Open Sans",sans-serif;
    font-size: 32px;
    line-height: 16px;
}

.tb-accordion .card .card-header a:not(.collapsed) .rotate-icon {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.tb-accordion .card .fa-angle-down {
    float: right;
}

.exhibitor-accordion .card{
    border-radius: 0rem;
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.exhibitor-card.card .card-header {
    padding: .75rem 0.8rem;
}
.exhibitor-details {
    h5 {
        font-size: 18px;
        color: #000;
        margin-bottom: 0;
    }

    h5 span {
        font-weight: normal;
    }
}

.exhibitor-card.card .card-body {
    padding: 0rem 0.9rem 0.9rem 0.9rem !important;
}

.exhibitor-card.card .card-body.pb-0 {
    padding: 0rem 0.9rem 0rem 0.9rem !important;
    padding-bottom: 0!important;;
}
.exhibitor-card.card .card-body.p-0 {
    padding: 0rem 0rem 0rem 0rem !important;
    padding-bottom: 0 !important;
    ;
}



.exhibitor-card.card .card-header,
.exhibitor-card.card .card-header:first-child,
.exhibitor-card.card .card-header:last-child
    {
    //background-color: rgba(0, 0, 0, .01);
    border-radius: 0rem;
    background-color: white;
    border-bottom: none;
    
}
.exhibitor-details {
    width: 100%;
}

.exhibitor-row{
    border-top:    0px solid rgba(0, 0, 0, .125);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.fa-1-5x {
    font-size: 1.5em;
}

@media (max-width: 991px) {
    .card.exhibitor-card {
        border: 0px solid rgba(0, 0, 0, .125);
        border-radius: 0rem;
        //box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.2) !important;
        box-shadow: none !important;
        margin-bottom:8px;
        
       //border: 0px solid rgba(0, 0, 0, .125);
    }
    .tb-accordion .card{
        border-left-width: 0 !important;
        border-right-width: 0 !important;
    }
/*
     .container-wrapper{
         padding-right: 0px !important;
         padding-left: 0px !important;
     }
     .row-wrapper {
         margin-right: 0px !important;
         margin-left: 0px !important;
     }
     .nomobile-ph {
         //padding-right: 0px !important;
         //padding-left: 0px !important;
     }
     
     */
     .exhibitor-listitem .exhibitor-card {
         margin-bottom:10px;
     }
     

     #navbarNavDropdown {
         padding-top: 10px;
     }
}

@media (min-width: 992px) {
    .card.exhibitor-card {
        margin-top:15px;
    }
}

img.img-fill {
    width: 100%;
    height: auto;
}




// carousel

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    padding: 4px 4px 4px 4px;
    background: no-repeat 50%/100% 100%;
    background-image: none;
    background-color: rgba(0, 0, 0, 0.5 );
}

.exhibitor-details .carousel {
    position: relative;
    width: 100%;
}

.carousel-control-prev {
    -ms-flex-align: left;
    align-items: left;
    -ms-flex-pack: left;
    justify-content: left;
    text-align: left;
}
.carousel-control-next {
    -ms-flex-align: right;
    align-items: right!important;
    -ms-flex-pack: right;
    //justify-content: right;
    justify-content: flex-end;
    text-align: right;
}

.iconbuttons {
    text-align: center;
    line-height: 20px;
    display: flex;
    width: 100%;
    div, a {
        transition-duration: .5s;
    }
    div.item,
    div.item-disabled {
        padding: 5px 2px 5px 2px;
        flex: 1;
    }

    div.item-disabled {
        color: lighten($primary-color,40%);
        //opacity: 40%;
    }

    div.title {
        padding: 0px 0px 0px 0px;

    }
    div.item:hover {
        background-color: lighten($primary-color, 50%);
        flex: 1;
    }
    span,
    div.title {
        font-weight: bold;
        font-size: 12px;
        line-height: 13px!important;
        margin-top: 3px;
    }
    a:hover {
        //color: $secondary-color;
        color: $primary-color;
    }
}

.icon-item {
    color: $primary-color;
    border-radius: 4px;
    border: solid 1px $primary-color;
    
    padding:5px;
    line-height: 20px!important;
    a:hover {
        color:$primary-color!important;
    }
    &:hover {
        background-color: lighten($primary-color, 50%);
    }
}


.bubble {
    position: relative;
    width: 100%;
    height: auto;
    padding: 5px;
    background: lighten($info-color, 15%);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    color: $primary-color;
    line-height: 20px;
    font-size: 13px;
}

.bubble:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 7px 6px 7px 0;
    border-color: transparent lighten($info-color, 15%);
    display: block;
    width: 0;
    z-index: 1;
    left: -6px;
    top: 11px;
}

div.linklist {
    font-size: 14px;
    line-height: 25px;
    font-weight: bold;
}

img.personpic {
    border: solid 1px $primary-color;
}

#mediaModal{

    .modal-header,
    .modal-footer,
    .modal-body {
        padding: .5rem .5rem;
        color: white;
        background-color: $primary-color;
        font-size: 15px;
        h5 {
            color: white;
        }
        .close {
            color:white;
            text-shadow: none;
        }
        //border-top: none;
        //border-bottom: none;
    }
    
    .modal-header{
        border-bottom: solid 1px #3F6AA5;
    }
    .modal-footer {
        border-top: solid 1px #3F6AA5;
    }
    .border-white {
        border: solid 2px white!important;
    }
    .border-faded {
        border: solid 2px rgba(0, 0, 0, 0.35) !important;
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        display: inline-block;
        width: 30px;
        height: 37px;
        padding: 4px 4px 4px 4px;
        background: no-repeat 50%/100% 100%;
        background-image: none;
        background-color: rgba(0, 0, 0, 0.5);
    }

}

.text-bold {
    font-weight: bold;
}
.btn {
    font-size: 1.1rem;
}

.alert-rounded {
    border-radius: 4px;
    border: 0;
}
.alert-mediumblue {
    background-color: #4c8ccd;
}


.list-primary li {
    background-color: $primary-color;
    color:white;
    a{
        color:white;
    }
    &:hover{
        background-color: darken($primary-color, 10%);
    }
    &.list-group-item {
        border: 1px solid rgba(255, 252, 252, 0.101);
    }
}



input[type=checkbox],
input[type=radio] {
    /* Double-sized Checkboxes */
    //-ms-transform: scale(1.5);
    /* IE */
    //-moz-transform: scale(1.5);
    /* FF */
    //-webkit-transform: scale(1.5);
    /* Safari and Chrome */
    //-o-transform: scale(1.5);
    /* Opera */
   //padding: 10px;
   //transform: scale(1.5);
   margin-top:10px;
}


.modal-dialog {
    h4 {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 0px;
        border-top: 0px solid #dee2e6;
    }
    label {
        margin-bottom: .2rem;
    }
}

.badge.text-bold {
    font-weight: bold!important;
}


.favbutton .indicator_isnofav {
    display: inline-block;
}
.favbutton .indicator_isfav {
    display: none;
}
.fav .favbutton .indicator_isnofav {
    display:none;
}
.fav .favbutton .indicator_isfav {
    display: inline-block;
}


.listmode_favoriten .showfavsbutton {
    color:red!important;
}

.niceGallery ul.entries-columns li {
    background-color: silver;
}
.niceGallery ul.entries-columns li a .show {
    height: 45px!important;
}


.long-and-truncated {
    //flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



/*
aspect ratio  | padding-bottom value
--------------|----------------------
    16:9      |       56.25%
    4:3       |       75%
    3:2       |       66.66%
    8:5       |       62.5%
*/
div.mediaholderpic {
    padding: 10px; 
    width:100%;

    .mediawrapper32 {
        padding: 10px;
        background: white;
        box-sizing: border-box;
        resize: horizontal;
        border: 1px dashed;
        overflow: auto;
        max-width: 100%;
        height: calc(100vh - 16px);
    }

    .mediawrapper32 div {
        width: 100%;
        padding-bottom: 75%;
        background: gold;
        /** <-- For the demo **/
    }
}

.text-red {
    color: red;
}

.custom-form label.small {
    font-size: 65%;
    margin-bottom: 0px;
}

@media (max-width: 360px) {

    .custom-form label.small {
        font-size: 90% !important;
        margin-bottom: 0px;
    }
}

.compact_lines {
    line-height: 140%;
}