/*
Promo boxes
*/

.promo-box{
     &.promo-padded{
        padding: 30px 30px 20px;
    }
    &.promo-border{
        border: 1px solid #eee;
        padding: 30px 30px 20px;
    }
    &.promo-dark{
        padding: 30px 30px 20px;
        h3,p{
            color:#fff;
        }
        p{
            opacity:0.8;
        }
    }
}
.promo-bg.promo-dark{
    padding: 80px 0;
    background-attachment: fixed;
    background-size: cover;
    background-position: top center;
    
}
.bg-parallax {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position:relative;
    overflow:hidden;
}